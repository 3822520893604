import React from 'react';
import {
  INCOME_OPORTUNITY_DATA,
  IO_DESCRIPTION,
  IO_HEADING,
} from './constants.js';
import styles from './IncomeOpportunity.module.scss';

type IncomeOpportunityHeaderProps = {
  heading: string;
  description: string;
};

const IncomeOpportunityHeader: React.FunctionComponent<IncomeOpportunityHeaderProps> =
  props => {
    const { heading, description } = props;
    return (
      <div className={styles.header_container}>
        <div className={styles.heading}>{heading}</div>
        <div className={styles.description}>{description}</div>
      </div>
    );
  };

function IncomeOpportunityComponent(): JSX.Element {
  return (
    <div className={styles.income_container}>
      <IncomeOpportunityHeader
        {...{
          heading: IO_HEADING,
          description: IO_DESCRIPTION,
        }}
      />
      <div className={styles.income_card_container}>
        {INCOME_OPORTUNITY_DATA.map(summary => (
          <div className={styles.income_card}>
            <div className={styles.income_card_icon}>
              <img src={summary.imgName} alt="Referrals" />
            </div>
            <div className={styles.card_description}>
              <div>{summary.title}</div>
              <div>{summary.description}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default IncomeOpportunityComponent;
