import {
  Dollar,
  Artboard,
  Calculator,
  Handbook,
} from '../../../../assets/images';

export const INCOME_OPORTUNITY_DATA = [
  {
    key: 1,
    title: '2-Level Referral Revenue Share Program',
    imgName: Dollar,
    description: 'Earn 25% from your referrals and 25% from their referrals.',
  },
  {
    key: 2,
    title: 'Potential Income Calculator',
    imgName: Calculator,
    description: 'Calculate your income potential!',
  },
  {
    key: 3,
    title: 'Business Dashboard',
    imgName: Artboard,
    description: 'Track your referrals, earnings, payouts and more.',
  },

  {
    key: 4,
    title: 'Mobile Dashboard',
    imgName: Handbook,
    description: 'App settings, Invite Wizard, Badges and more.',
  },
  {
    key: 5,
    title: 'Invite Wizard',
    imgName: Handbook,
    description:
      'Invite using Text inviter, manual and automated emails, social sharing and QR Code sharing',
  },
  {
    key: 6,
    title: 'Contact Inviter',
    imgName: Handbook,
    description: `Track your contact's from Invited to Signed Up and Subscribed.`,
  },
];

export const IO_HEADING = 'Income Opportunity';
export const IO_DESCRIPTION =
  'Make money sharing your referral link to your family, friends, followers and everyone you know and paid every time one of your referrals sign up and subscribe!';
