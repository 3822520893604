import {
  Pause,
  ArtBoard,
  Camera2,
  Videoedit,
  Sheild,
  Share,
  Tiktok,
} from '../../../../assets/images';

export const CAMERA_DATA = [
  {
    key: 1,
    title: 'Patented Special Effects',
    description:
      'Create video "quickies" using 1-touch fast & slow motion editing  while recording.',
    imgName: Pause,
  },
  {
    key: 2,
    title: 'Bitmojis',
    description: 'Add your Bitmojis directly to your quickies.',
    imgName: ArtBoard,
  },
  {
    key: 3,
    title: 'Pic2Art',
    description: '(iPhone only) Convert your pictures into beautiful art.',
    imgName: Camera2,
  },
  {
    key: 4,
    title: 'Video Editor',
    description: 'Quickly trim, crop, cut and share your videos "quickies".',
    imgName: Videoedit,
  },
  {
    key: 5,
    title: 'Protect & Promote',
    description: 'Watermark your video quickies with your channel name.',
    imgName: Sheild,
  },
  {
    key: 6,
    title: 'Social Sharing',
    description: 'Share your "quickies" on social media.',
    imgName: Share,
    imgLogo: Tiktok,
  },
];

export const CAMERA_APP_HEADING = 'QuickCam Camera App';
export const CAMERA_APP_DESCRIPTION =
  'Full featured video camera and video editor.';
