export const SUBSCRIPTION_LEVEL_DATA = {
  android: {
    premium: {
      section1: {
        'QuickCam Camera Mode': [
          ' Record in 2x, 3x, 4x and 5x fast motion',
          'Record in -2x, -3x, -4x and -5x slow motion',
          'Record in normal speed',
          'Record up to 3 minutes',
          'Save Mode',
        ],
        'Quickie Editor': [
          'Edit existing videos, up to 5 min',
          'Edit up to 20 segments',
          'Bitmoji integration',
          'Trim, Cut & Crop',
        ],
      },
      section2: {
        'Pic2Art (iPhone only)': ['44 Pic2Art filters', 'Pic2Art Photo Editor'],
        'Mobile Dashboard': [
          'Referral Duplication System',
          ' Custom Referral page for affiliates',
          ' Invite Wizard with Text and Email inviter',
          'QR Code Profile Badge ',
          'Android Premium Subscriber Badge or',
          'iPhone Premium Subscriber Badge',
        ],
      },
    },
    advanced: {
      section1: {
        'QuickCam Camera Mode': [
          ' Record in 2x, 3x and 4x fast motion',
          'Record in -2x, -3x and -4x  slow motion',
          'Record in normal speed',
          'Record up to 2 minutes',
          'Save Mode',
        ],
        'Quickie Editor': [
          'Edit existing videos, up to 2 min',
          'Bitmoji integration',
          'Trim, Cut & Crop',
          'Watermarks',
        ],
      },
      section2: {
        'Pic2Art (iPhone only)': ['44 Pic2Art filters', 'Pic2Art Photo Editor'],
        'Mobile Dashboard': [
          'Referral Duplication System',
          ' Custom Referral page for affiliates',
          ' Invite Wizard with Text and Email inviter',
          'QR Code Profile Badge ',
          'Android Premium Subscriber Badge or',
          'iPhone Premium Subscriber Badge',
        ],
      },
    },
    basic: {
      section1: {
        'QuickCam Camera Mode': [
          ' Record in 2x and 3x fast motion',
          'Record in -2x and -3x  slow motion',
          'Record in normal speed',
          'Record up to 1 minutes',
        ],
        'Quickie Editor': [
          'Edit existing videos, up to 1 min',
          'Bitmoji integration',
          'Trim, Cut & Crop',
          'Watermarks',
          'Referral link sticker',
          'Share on all supported social media',
        ],
      },
      section2: {
        'Mobile Dashboard': [
          'Referral Duplication System',
          'Custom Referral page for affiliates',
          'Invite Wizard with Text and Email inviter',
          'QR Code Profile Badge ',
          'Android Premium Subscriber Badge or',
          'iPhone Premium Subscriber Badge',
        ],
        'Business Dashboard (Web access)': [
          'Free while in Beta',
          'Automated email inviter',
          'Custom Referral pages',
        ],
      },
    },
    free: {
      section1: {
        '7-Day Free Trial': [
          'Access to all Premium Features',
          'No credit card required',
        ],
        'QuickCam Camera Mode': [
          ' Record in 2x and 3x fast motion',
          'Record in normal speed',
          'Record up to 30 seconds',
        ],
        'Mobile Dashboard': [
          'Referral Duplication System',
          'Invite Wizard with Text and Email inviter',
          'QR Code Profile Badge ',
        ],
      },
      section2: {
        'Quickie Editor': [
          'Edit existing videos, up to 30 seconds',
          'Edit upto 4 segments',
          'Bitmoji integration',
          'Trim, Cut & Crop',
          'Watermarks',
          'Referral link sticker',
          'Share on all supported social media',
        ],
        'Business Dashboard (Web access)': [
          'Free while in Beta',
          'Automated email inviter',
        ],
      },
    },
  },
  apple: {
    premium: {
      section1: {
        'QuickCam Camera Mode': [
          ' Record in 2x, 3x, 4x and 5x fast motion',
          'Record in -2x, -3x, -4x and -5x slow motion',
          'Record in normal speed',
          'Record up to 3 minutes',
          'Save Mode',
        ],
        'Quickie Editor': [
          'Edit existing videos, up to 5 min',
          'Edit up to 20 segments',
          'Bitmoji integration',
          'Trim, Cut & Crop',
        ],
      },
      section2: {
        'Pic2Art (iPhone only)': ['44 Pic2Art filters', 'Pic2Art Photo Editor'],
        'Mobile Dashboard': [
          'Referral Duplication System',
          ' Custom Referral page for affiliates',
          ' Invite Wizard with Text and Email inviter',
          'QR Code Profile Badge ',
          'Android Premium Subscriber Badge or',
          'iPhone Premium Subscriber Badge',
        ],
      },
    },
    advanced: {
      section1: {
        'QuickCam Camera Mode': [
          ' Record in 2x, 3x and 4x fast motion',
          'Record in -2x, -3x and -4x  slow motion',
          'Record in normal speed',
          'Record up to 2 minutes',
          'Save Mode',
        ],
        'Quickie Editor': [
          'Edit existing videos, up to 2 min',
          'Bitmoji integration',
          'Trim, Cut & Crop',
          'Watermarks',
        ],
      },
      section2: {
        'Pic2Art (iPhone only)': ['44 Pic2Art filters', 'Pic2Art Photo Editor'],
        'Mobile Dashboard': [
          'Referral Duplication System',
          ' Custom Referral page for affiliates',
          ' Invite Wizard with Text and Email inviter',
          'QR Code Profile Badge ',
          'Android Premium Subscriber Badge or',
          'iPhone Premium Subscriber Badge',
        ],
      },
    },
    basic: {
      section1: {
        'QuickCam Camera Mode': [
          ' Record in 2x and 3x fast motion',
          'Record in -2x and -3x  slow motion',
          'Record in normal speed',
          'Record up to 1 minutes',
        ],
        'Quickie Editor': [
          'Edit existing videos, up to 1 min',
          'Bitmoji integration',
          'Trim, Cut & Crop',
          'Watermarks',
          'Referral link sticker',
          'Share on all supported social media',
        ],
      },
      section2: {
        'Mobile Dashboard': [
          'Referral Duplication System',
          'Custom Referral page for affiliates',
          'Invite Wizard with Text and Email inviter',
          'QR Code Profile Badge ',
          'Android Premium Subscriber Badge or',
          'iPhone Premium Subscriber Badge',
        ],
        'Business Dashboard (Web access)': [
          'Free while in Beta',
          'Automated email inviter',
          'Custom Referral pages',
        ],
      },
    },
    free: {
      section1: {
        '7-Day Free Trial': [
          'Access to all Premium Features',
          'No credit card required',
        ],
        'QuickCam Camera Mode': [
          ' Record in 2x and 3x fast motion',
          'Record in normal speed',
          'Record up to 30 seconds',
        ],
        'Mobile Dashboard': [
          'Referral Duplication System',
          'Invite Wizard with Text and Email inviter',
          'QR Code Profile Badge ',
        ],
      },
      section2: {
        'Quickie Editor': [
          'Edit existing videos, up to 30 seconds',
          'Edit upto 4 segments',
          'Bitmoji integration',
          'Trim, Cut & Crop',
          'Watermarks',
          'Referral link sticker',
          'Share on all supported social media',
        ],
        'Business Dashboard (Web access)': [
          'Free while in Beta',
          'Automated email inviter',
        ],
      },
    },
  },
};

export const BG_COLOR = ['#4d83d5', '#569951', '#e0b82a', '#a5a5a5'];
export const PLANS = ['premium', 'advanced', 'basic', 'free'];
