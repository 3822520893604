import React from 'react';
import { Step, Stepper, StepLabel } from '@mui/material';
import { Link } from 'react-router-dom';
import { useLocation, useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import styles from './home.module.scss';
import FaqsComponent from './Faqs/faq.component';
import GettingStartedComponent from './GettingStarted/gettingstarted.component';
import SummaryComponent from './Summary/summary.component';
import CameraApp from './CameraApp/cameraapp.component';
import IncomeOpportunity from './IncomeOpportunity/IncomeOpportunity.component';
import SubscriptionLevel from './SubscriptionLevel/subscriptionLevel.component';
import { Backicon } from '../../../assets/images';
import ENVIRONMENTS from '../../../environments';
import { RootState } from '../../../store/create-store';

// function getModalStyle() {
//   return {
//     // top: '80%',
//     // left: '50%',
//     // transform: 'translate(-50%, -50%)',
//   };
// }

// interface Props {
//   isOpen: boolean;
//   handleClose: () => void;
// }

function getStepContent(step: number) {
  switch (step) {
    case 0:
      return <CameraApp />;
    case 1:
      return <IncomeOpportunity />;
    case 2:
      return <SubscriptionLevel />;
    case 3:
      return <FaqsComponent />;
    case 4:
      return <SummaryComponent />;
    case 5:
      return <GettingStartedComponent />;
    // case 4:
    //   return <AbountUsComponent />;
    // case 5:
    //   return <GettingStartedComponent />;
    // case 8:
    //   return <GettingStartedComponent />;
    default:
      return <></>;
  }
}

const useQuery = (name: string) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  return query.get(name);
};
// const step =;

function QuickTourHome(): JSX.Element {
  const history = useHistory();
  const commonState = useSelector((state: RootState) => state.common);
  const { auth } = commonState;
  console.log(auth);

  // const [modalStyle] = useState(getModalStyle);
  const [activeStep, setActiveStep] = React.useState(Number(useQuery('step')));

  const updateQueryURL = (newStep: number) => {
    const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?step=${newStep}`;
    window.history.pushState({ path: newUrl }, '', newUrl);
  };

  const redirectToSignUp = () => {
    const redirectUri = window.location.origin;
    window.localStorage.setItem('redirect_uri', redirectUri);
    const signUphURL = `${ENVIRONMENTS.KEYCLOAK_ACCOUNT}/protocol/openid-connect/registrations?client_id=${ENVIRONMENTS.KEYCLOAK_CLIENT_ID}&response_mode=fragment&response_type=code&login=true&redirect_uri=${loginU}&from=Signup`;
    window.location.href = signUphURL;
  };

  const handleNext = () => {
    if (activeStep < 5) {
      const newStep = activeStep + 1;
      setActiveStep(newStep);
      updateQueryURL(newStep);
    } else {
      redirectToSignUp();
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      history.push('/quick-tour');
    } else {
      const newStep = activeStep - 1;
      setActiveStep(newStep);
      updateQueryURL(newStep);
    }
  };

  return (
    <div className={styles.quick_tour_home}>
      <div className={styles.quick_tour_header}>
        <Link to={`/quick-tour?step=${activeStep}`}>
          <div className={styles.homeLink}>
            <span>
              <img src={Backicon} alt="backicon" />
            </span>
            <span>Home</span>
          </div>
        </Link>
      </div>
      <div className={styles.quick_tour_step}>
        <Stepper nonLinear activeStep={activeStep} className={styles.stepper}>
          {[1, 2, 3, 4, 5, 6].map((label, index) => (
            <Step key={label} completed={index < activeStep}>
              <StepLabel />
            </Step>
          ))}
        </Stepper>
      </div>
      <div className={styles.quick_tour_content}>
        {getStepContent(activeStep)}
      </div>
      <div className={styles.action_container}>
        <div className={styles.button_container}>
          <div>
            <button
              type="button"
              className={styles.button_cancel}
              onClick={handleBack}
            >
              {activeStep === 0 ? 'Home' : 'Back'}
            </button>
          </div>
          <div>
            {auth?.token && activeStep === 5 ? (
              <button
                type="button"
                className={styles.button_done}
                onClick={() => history.push('/u/dashboard')}
              >
                Go to dashboard
              </button>
            ) : (
              <button
                type="button"
                className={styles.button_done}
                onClick={handleNext}
              >
                {activeStep === 5 ? 'Sign up' : 'Continue'}
              </button>
            )}
          </div>
        </div>
        <div className={styles.copyright}>
          About Us | © Copyright 2023 QuickCam, LLC. All rights reserved.
        </div>
      </div>
    </div>
  );
}

export default QuickTourHome;
