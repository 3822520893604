import {
  IconGlobal,
  IconDigital,
  IconWebsite,
  IconLowCost,
  IconLowMakeMoney,
} from '../../../../assets/svg';
import { Iconwebsite } from '../../../../assets/images';

export const SUMMARY_DATA = [
  {
    key: 1,
    title: 'Downloadable Digital Product',
    description: '"Next Generation" patented camera app.  ',
    imgName: IconDigital,
  },
  {
    key: 2,
    title: 'Affordable Worldwide',
    description: 'Priced low for emerging global markets.',
    imgName: IconLowCost,
  },
  {
    key: 3,
    title: 'Huge Global Market',
    description: '4.6 Billion potential users. Everyone w/ a compatible.',
    imgName: IconGlobal,
  },
  {
    key: 4,
    title: 'Make Money',
    description:
      '50% referral bouns commissions paid on 2 levels. Earn matching bonus from everyone you refer!. ',
    imgName: IconLowMakeMoney,
  },
  {
    key: 5,
    title: 'Track Your Earnings',
    description: 'Free Mobile Dashboard and Business Dashboard free trial.',
    imgName: IconWebsite,
  },
  {
    key: 5,
    title: 'Content Creators',
    description: 'Create Quickies and Pic2Art w/ QuickCam Camera App.',
    imgName: Iconwebsite,
  },
];

export const SUMMARY_HEADING = 'Summary';
export const SUMMARY_DESCRIPTION = 'Camera App and Referral Program Highlights';
