import React from 'react';
import Switch from '@mui/material/Switch';
import styles from './subscriptionLevel.module.scss';
import {
  blueregular,
  greenregular,
  yellowregular,
  greyregular,
  switchbutton1,
  switchbutton2,
  androidBlue,
  androidGreen,
  androidYellow,
  androidGray,
} from '../../../../assets/images';
import { BG_COLOR, PLANS, SUBSCRIPTION_LEVEL_DATA } from './constants.js';

type SubscriptionComponentHeaderProps = {
  bgColor1: string;
  bgColor2: string;
  androidPrice: string;
  applePrice: string;
  subscriptionName: string;
};

const SubscriptionType: React.FunctionComponent<SubscriptionComponentHeaderProps> =
  props => {
    const { bgColor1, bgColor2, androidPrice, applePrice, subscriptionName } =
      props;
    return (
      <div>
        <div style={{ height: '5rem' }} />
        <div
          style={{ background: `linear-gradient(${bgColor2}, ${bgColor1})` }}
          className={styles.subscription_inner_card}
        >
          <div className={styles.plan_label} style={{ background: bgColor1 }}>
            {subscriptionName}
          </div>
          <div>
            <span>{`Android:$${androidPrice}/month`}</span>
            <span>{`iPhone:$${applePrice}/month`}</span>
          </div>
        </div>
      </div>
    );
  };

function SubscriptionLevel(): JSX.Element {
  const [checked, setChecked] = React.useState(true);
  const [index, setIndex] = React.useState(0);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const getDataSection = (section: string, data: any) => {
    const plan = PLANS[index];
    const os = checked ? 'apple' : 'android';
    const osSpecificData = data[os];
    const osData = osSpecificData;
    const indexData = osData[plan];
    const sectionData = indexData[section];
    return Object.keys(sectionData).map(key => (
      <>
        <div>{key}</div>
        <ul>
          {sectionData[key].map((items: any) => (
            <li>{items}</li>
          ))}
        </ul>
      </>
    ));
  };

  return (
    <div>
      {/* HEADER */}
      <div className={styles.header_container}>
        <div className={styles.heading}>Subscription Levels</div>
        <div className={styles.switch_container}>
          <div>Select your device type</div>
          <div className={styles.switch_buttons}>
            <div>
              <img
                src={switchbutton1}
                alt="Track Your Earnings"
                className={styles.switch_icon}
              />
            </div>

            <div>
              <Switch
                checked={checked}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </div>
            <div>
              <img
                src={switchbutton2}
                alt="Track Your Earnings"
                className={styles.switch_icon}
              />
            </div>
          </div>
        </div>
      </div>
      {/* CARDS */}
      <div className={styles.subscription_container}>
        <div className={styles.subscription_os_container}>
          <div
            className={styles.subscription_card}
            onMouseOver={() => setIndex(0)}
            onFocus={() => setIndex(0)}
            style={{ top: index === 0 ? '5px' : '-5px' }}
          >
            <img src={checked ? blueregular : androidBlue} alt="os" />
            <SubscriptionType
              bgColor1="#4d83d5"
              bgColor2="#97C2F3"
              subscriptionName="Premium"
              androidPrice="1.99"
              applePrice="1.99"
            />
          </div>
          <div
            className={styles.subscription_card}
            onMouseOver={() => setIndex(1)}
            onFocus={() => setIndex(1)}
            style={{ top: index === 1 ? '5px' : '-5px' }}
          >
            <img src={checked ? greenregular : androidGreen} alt="os" />
            <SubscriptionType
              bgColor1="#569951"
              bgColor2="#ADDC9A"
              subscriptionName="Advanced"
              androidPrice=".99"
              applePrice="1.99"
            />
          </div>
          <div
            className={styles.subscription_card}
            onMouseOver={() => setIndex(2)}
            onFocus={() => setIndex(2)}
            style={{ top: index === 2 ? '5px' : '-5px' }}
          >
            <img src={checked ? yellowregular : androidYellow} alt="os" />
            <SubscriptionType
              bgColor1="#e0b82a"
              bgColor2="#FFEEA8"
              subscriptionName="Basic"
              androidPrice=".99"
              applePrice=".99"
            />
          </div>
          <div
            className={styles.subscription_card}
            onMouseOver={() => setIndex(3)}
            onFocus={() => setIndex(3)}
            style={{ top: index === 3 ? '5px' : '-5px' }}
          >
            <img src={checked ? greyregular : androidGray} alt="os" />
            <SubscriptionType
              bgColor1="#a5a5a5"
              bgColor2="#D7D7D7"
              subscriptionName="Free"
              androidPrice="0"
              applePrice="0"
            />
          </div>
        </div>
        <div
          className={styles.bottom_border}
          style={{ background: BG_COLOR[index] }}
        />
      </div>
      {/* CONTENT */}
      <div className={styles.subscription_details_container}>
        <div>{getDataSection('section1', SUBSCRIPTION_LEVEL_DATA)}</div>
        <div>{getDataSection('section2', SUBSCRIPTION_LEVEL_DATA)}</div>
      </div>
    </div>
  );
}

export default SubscriptionLevel;
