import React from 'react';
import styles from './gettingstarted.module.scss';
import StartIcon from '../../../../assets/images/start.png';
import {
  GETTING_STARTED_DESCRIPTION,
  GETTING_STARTED_DETAILS,
  GETTING_STARTED_HEADING,
} from './constants.js';

type GettingStartedComponentHeaderProps = {
  heading: string;
  description: string;
};

const GettingStartedComponentHeader: React.FunctionComponent<GettingStartedComponentHeaderProps> =
  props => {
    const { heading, description } = props;
    return (
      <div className={styles.header_container}>
        <div className={styles.heading}>{heading}</div>
        <div className={styles.description}>{description}</div>
      </div>
    );
  };

function GettingStartedComponent(): JSX.Element {
  return (
    <div className={styles.getting_started_container}>
      <GettingStartedComponentHeader
        {...{
          heading: GETTING_STARTED_HEADING,
          description: GETTING_STARTED_DESCRIPTION,
        }}
      />
      <div className={styles.getting_started_card_container}>
        <img
          src={StartIcon}
          alt="Get Started"
          className={styles.getting_started_icon}
        />
        <div className={styles.description}>{GETTING_STARTED_DETAILS}</div>
      </div>
    </div>
  );
}

export default GettingStartedComponent;
