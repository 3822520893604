import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Icon,
} from '@mui/material';
import styles from './faq.module.scss';
import { FAQ_DATA, FAQ_DESCRIPTION, FAQ_HEADING } from './constants.js';

type FaqsHeaderProps = {
  heading: string;
  description: string;
};

const FaqsHeader: React.FunctionComponent<FaqsHeaderProps> = props => {
  const { heading, description } = props;
  return (
    <div className={styles.header_container}>
      <div className={styles.heading}>{heading}</div>
      <div className={styles.description}>{description}</div>
    </div>
  );
};
function FaqsComponent(): JSX.Element {
  return (
    <div className={styles.faq_container}>
      <FaqsHeader
        {...{
          heading: FAQ_HEADING,
          description: FAQ_DESCRIPTION,
        }}
      />
      <div className={styles.faq_card_container}>
        {FAQ_DATA.map(data => (
          <Accordion key={data.key} sx={{ boxShadow: 0 }}>
            <AccordionSummary
              expandIcon={
                <div className={styles.expand}>
                  <Icon style={{ color: '#007AFF' }}>add</Icon>
                </div>
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className={styles.headingWrapper}>
                <Typography className={styles.count}>{data.key}</Typography>
                <Typography className={styles.heading} align="left">
                  {data.title}
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Typography align="left" className={styles.content}>
                {data.content}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
}
export default FaqsComponent;
