export const FAQ_DATA = [
  {
    key: '01',
    title: 'How does recording in fast and slow motion in real time work?',
    content: `Simply touch and hold the record button, then move your finger to the
        right to record in fast motion, and move your finger to the left for
        slow motion.Return to the middle for normal speed.`,
  },
  {
    key: '02',
    title: 'How much does it cost?',
    content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit.Suspendisse
        malesuada lacus ex, sit amet blandit leo lobortis eget.`,
  },
  {
    key: '03',
    title: 'Why is the price so low?',
    content: `QuickCam is a high quality patented camera app that we could have
        charged a much higher price for.But we didn & apos;t want price to be a
        barrier to entry to QuickCam.`,
  },
  {
    key: '04',
    title: 'How can I earn money?',
    content: `Share your videos with your referral link in your private snaps, direct
  messages & social media posts.`,
  },
  {
    key: '05',
    title: 'How much money can I make?',
    content: ` It depends on you.We & apos;ve provided an Affiliate Calculator so you
        can find out.
`,
  },
  {
    key: '06',
    title: 'What is the QuickCam Business Dashboard?',
    content: ` It & apos;s a management tool to keep track of your referrals, earnings,
    payout and more.`,
  },
  {
    key: '07',
    title: 'Is this a networking marketing or mlm?',
    content: ` No.You get paid the 50 % revenue share only from your referrals & apos;
  subscriptions.`,
  },
  {
    key: '08',
    title: 'What is QuickCam Pre-Launch?',
    content: ` Pre - Launch is the period of time when you can use QuickCam for free to
        help us test the QuickCam iPhone & Android apps and the QuickCam
        Business Dashboard.`,
  },
  {
    key: '09',
    title: 'What and when is QuickCam Official Launch?',
    content: `Official Launch is on December 10, 2021.`,
  },
  {
    key: '10',
    title: 'What is a Founding Member?',
    content: `Founding Members are users who sign up before March 31, 2022. As a Founding Member, your "profile card" in your referral page will be distinguished with the Founding Member Badge. Early Adopters are also Founding Members.`,
  },
  {
    key: '11',
    title: 'What are Premium Extension Gift Card?',
    content:
      'Premium Gift Cards extend the 3 Day Free Trial with an additional 7 days of Premium access.',
  },
  {
    key: '15',
    title: 'How do you get Premium Extension Gift Card?',
    content:
      'You get 40 complementary Premium Gift Cards you can immediately share when you sign up and register for free.',
  },
  {
    key: '12',
    title: 'What is an Early Adopter?',
    content:
      'Early Adopter are users who sign up before Mar 1, 2023. As an Early Adopter, your "profile card" in your referral page will be distinguished with the Early Adopter Badge.',
  },
  {
    key: '13',
    title: 'What is ProManager?',
    content: ` ProManager is a free identity provider that QuickCam uses to create and
        manage your channel name and password.`,
  },
  {
    key: '14',
    title: 'How can I create a ProManager account?',
    content: `Click on the & quot;Sign up & quot; button below in the Getting Started section.`,
  },
];

export const FAQ_HEADING = 'FAQ';
export const FAQ_DESCRIPTION = 'Frequently Asked Questions';
