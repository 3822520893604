import React from 'react';
import styles from './cameraapp.module.scss';
import {
  CAMERA_APP_DESCRIPTION,
  CAMERA_APP_HEADING,
  CAMERA_DATA,
} from './constants.js';

type CameraComponentHeaderProps = {
  heading: string;
  description: string;
};

const CameraComponentHeader: React.FunctionComponent<CameraComponentHeaderProps> =
  props => {
    const { heading, description } = props;
    return (
      <div className={styles.header_container}>
        <div className={styles.heading}>{heading}</div>
        <div className={styles.description}>{description}</div>
      </div>
    );
  };

function CameraAppComponent(): JSX.Element {
  return (
    <div className={styles.camera_app_container}>
      <CameraComponentHeader
        {...{
          heading: CAMERA_APP_HEADING,
          description: CAMERA_APP_DESCRIPTION,
        }}
      />
      <div className={styles.camera_card_container}>
        {CAMERA_DATA.map(camera => (
          <div className={styles.camera_card}>
            <div className={styles.camera_card_icon}>
              <img src={camera.imgName} alt="Camera App" />
            </div>
            <div className={styles.card_description}>
              <div>{camera.title}</div>
              <div>{camera.description}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CameraAppComponent;
