import React from 'react';
import {
  SUMMARY_DATA,
  SUMMARY_DESCRIPTION,
  SUMMARY_HEADING,
} from './constants.js';
import styles from './summary.module.scss';

type SummaryComponentHeaderProps = {
  heading: string;
  description: string;
};

const SummaryComponentHeader: React.FunctionComponent<SummaryComponentHeaderProps> =
  props => {
    const { heading, description } = props;
    return (
      <div className={styles.header_container}>
        <div className={styles.heading}>{heading}</div>
        <div className={styles.description}>{description}</div>
      </div>
    );
  };

function SummaryComponent(): JSX.Element {
  return (
    <div className={styles.summary_container}>
      <SummaryComponentHeader
        {...{
          heading: SUMMARY_HEADING,
          description: SUMMARY_DESCRIPTION,
        }}
      />
      <div className={styles.summary_card_container}>
        {SUMMARY_DATA.map(summary => (
          <div className={styles.summary_card}>
            <div className={styles.summary_card_icon}>
              <img src={summary.imgName} alt="Referrals" />
            </div>
            <div className={styles.card_description}>
              <div>{summary.title}</div>
              <div>{summary.description}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SummaryComponent;
